<template>
	<v-card :loading="Object.keys(data).length == 0">
		<v-container fluid>
			<div class="d-flex" style="gap: 16px">
				<v-menu :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex flex-column align-center">
							<span class="align-self-start">Previsto: {{ parseDate(data.envioCarta1) }}</span>
							<v-btn
								v-on="on"
								v-bind="attrs"
								x-large
								color="warning"
								:outlined="!envios.carta1"
								:disabled="[envios.carta2, envios.cartaCorte].some(x => x)"
							>1ª Carta {{ envios.carta1 ? 'Enviada' : 'Sin enviar' }}</v-btn>
							<span class="text-caption">
								<v-icon small left>mdi-update</v-icon>
								{{ parseDate(envios.carta1) }}
							</span>
						</div>
					</template>
					<v-card>
						<v-date-picker v-model="envios.carta1"></v-date-picker>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn small @click="envios.carta1 = false">borrar</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>

				<v-menu :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex flex-column align-center">
							<span class="align-self-start">Previsto: {{ parseDate(data.envioCarta2) }}</span>
							<v-btn
								v-on="on"
								v-bind="attrs"
								x-large
								color="deep-orange"
								:outlined="!envios.carta2"
								:disabled="!envios.carta1 || envios.cartaCorte"
							>2ª Carta {{ envios.carta2 ? 'Enviada' : 'Sin enviar' }}</v-btn>
							<span class="text-caption">
								<v-icon small left>mdi-update</v-icon>
								{{ parseDate(envios.carta2) }}
							</span>
						</div>
					</template>
					<v-card>
						<v-date-picker v-model="envios.carta2"></v-date-picker>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn small @click="envios.carta2 = false">borrar</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>

				<v-menu :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex flex-column align-center">
							<span class="align-self-start">Previsto: {{ parseDate(data.envioCarta3) }}</span>
							<v-btn
								v-on="on"
								v-bind="attrs"
								x-large
								color="error"
								:outlined="!envios.cartaCorte"
								:disabled="[envios.carta2, envios.carta1].some(x => !x)"
							>Carta de corte {{ envios.cartaCorte ? 'Enviada' : 'Sin enviar' }}</v-btn>
							<span class="text-caption">
								<v-icon small left>mdi-update</v-icon>
								{{ parseDate(envios.cartaCorte) }}
							</span>
						</div>
					</template>
					<v-card>
						<v-date-picker v-model="envios.cartaCorte"></v-date-picker>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn small @click="envios.cartaCorte = false">borrar</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</div>
			<v-row class="mt-3">
				<v-col cols="auto">
					<v-alert
						type="info"
						class="mb-0"
						dense
						text
					>Se marca como enviada para verlo reflejado en la tabla, no se envía nada realmente</v-alert>
				</v-col>
				<v-col cols="12">
					<v-btn @click.stop="guardarCambio" color="primary" x-large>
						<v-icon left>mdi-content-save</v-icon>Guardar cambios
					</v-btn>
				</v-col>
			</v-row>
			<!-- {{ envios }} -->
		</v-container>
	</v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados.js";

export default {
	data() {
		return {
			envios: {
				carta1: false,
				carta2: false,
				cartaCorte: false,
			},
			data: {},
		}
	},
	props: {
		IdCarteraCobro: String | Number,
	},
	mounted() {
		axios({
			url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
			method: 'GET',
			params: {
				token: this.$store.getters.getJwtEmpresa,
				IdCarteraCobro: this.IdCarteraCobro
			}
		}).then(({ data }) => {
			this.data = parseImpagados([data])[0];
		});

		axios({
			url: `${process.env.VUE_APP_OUR_API_URL}/avisosFormalesImpagados.php`,
			method: 'GET',
			params: {
				token: this.$store.getters.getJwtEmpresa,
				IdCarteraCobro: this.IdCarteraCobro
			}
		}).then(res => {
			if (res.data !== null) {
				this.envios.carta1 = res.data.carta1 ? parseDate(res.data.carta1, true) : false;
				this.envios.carta2 = res.data.carta2 ? parseDate(res.data.carta2, true) : false;
				this.envios.cartaCorte = res.data.cartaCorte ? parseDate(res.data.cartaCorte, true) : false;
			}
		})
	},
	methods: {
		parseDate,
		guardarCambio() {
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/avisosFormalesImpagados.php`,
				method: 'POST',
				data: {
					token: this.$store.getters.getJwtEmpresa,
					envios: {
						carta1: parseDate(this.envios.carta1, true) || false,
						carta2: parseDate(this.envios.carta2, true) || false,
						cartaCorte: parseDate(this.envios.cartaCorte, true) || false,
					},
					IdCarteraCobro: this.IdCarteraCobro
				}
			}).then(res => {
				this.$root.$emit('snack', 'Envíos cambiados correctamente');
			}).catch(err => {
				this.$root.$emit('snack', 'Ha ocurrido un error al apuntar los cambios');
			})
		}
	},
}
</script>