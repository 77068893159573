var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":Object.keys(_vm.data).length == 0}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"align-self-start"},[_vm._v("Previsto: "+_vm._s(_vm.parseDate(_vm.data.envioCarta1)))]),_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","color":"warning","outlined":!_vm.envios.carta1,"disabled":[_vm.envios.carta2, _vm.envios.cartaCorte].some(function (x) { return x; })}},'v-btn',attrs,false),on),[_vm._v("1ª Carta "+_vm._s(_vm.envios.carta1 ? 'Enviada' : 'Sin enviar'))]),_c('span',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-update")]),_vm._v(" "+_vm._s(_vm.parseDate(_vm.envios.carta1))+" ")],1)],1)]}}])},[_c('v-card',[_c('v-date-picker',{model:{value:(_vm.envios.carta1),callback:function ($$v) {_vm.$set(_vm.envios, "carta1", $$v)},expression:"envios.carta1"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.envios.carta1 = false}}},[_vm._v("borrar")])],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"align-self-start"},[_vm._v("Previsto: "+_vm._s(_vm.parseDate(_vm.data.envioCarta2)))]),_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","color":"deep-orange","outlined":!_vm.envios.carta2,"disabled":!_vm.envios.carta1 || _vm.envios.cartaCorte}},'v-btn',attrs,false),on),[_vm._v("2ª Carta "+_vm._s(_vm.envios.carta2 ? 'Enviada' : 'Sin enviar'))]),_c('span',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-update")]),_vm._v(" "+_vm._s(_vm.parseDate(_vm.envios.carta2))+" ")],1)],1)]}}])},[_c('v-card',[_c('v-date-picker',{model:{value:(_vm.envios.carta2),callback:function ($$v) {_vm.$set(_vm.envios, "carta2", $$v)},expression:"envios.carta2"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.envios.carta2 = false}}},[_vm._v("borrar")])],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"align-self-start"},[_vm._v("Previsto: "+_vm._s(_vm.parseDate(_vm.data.envioCarta3)))]),_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","color":"error","outlined":!_vm.envios.cartaCorte,"disabled":[_vm.envios.carta2, _vm.envios.carta1].some(function (x) { return !x; })}},'v-btn',attrs,false),on),[_vm._v("Carta de corte "+_vm._s(_vm.envios.cartaCorte ? 'Enviada' : 'Sin enviar'))]),_c('span',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-update")]),_vm._v(" "+_vm._s(_vm.parseDate(_vm.envios.cartaCorte))+" ")],1)],1)]}}])},[_c('v-card',[_c('v-date-picker',{model:{value:(_vm.envios.cartaCorte),callback:function ($$v) {_vm.$set(_vm.envios, "cartaCorte", $$v)},expression:"envios.cartaCorte"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.envios.cartaCorte = false}}},[_vm._v("borrar")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","dense":"","text":""}},[_vm._v("Se marca como enviada para verlo reflejado en la tabla, no se envía nada realmente")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){$event.stopPropagation();return _vm.guardarCambio($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("Guardar cambios ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }